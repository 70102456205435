import React, { useEffect, useState } from 'react';
import { getProductByLangId } from '../../../Service/ProductServices';
import { button_icon, call_icon, chat_icon, logo_modena, mona_apron, no_image1, vcall_icon, url_ar, camera } from '../../../Images';
import { useParams } from 'react-router-dom';
import Overlay from "../../../Components/Overlay/Overlay";
import parse from 'html-react-parser';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const { $ } = window;

let processingId = -1;

const ProductDetailPage = () => {

    const [slideUp, setSlideup] = useState(false);
    const { lang, id } = useParams();

    const [state, setStates] = useState({
        isDataExist: false,
        isLoaded: false,
        tagline: "",
        title: "",
        feature: [],
        manualBook: "",
        urlAr: "",
        productImage: [],
        banner: "",
        icons: [],
        iconDumps: [],
        dimensions: {},
        videoPreview: false,
        processing: false,
        imageIndex: -1,
        show3D: true,
        global: false
    });

    const startProcessing = () => {
        processingId = setTimeout(() => {
            setState({ processing: true });
        }, 150);
    };

    const setState = value => {
        setStates({ ...state, ...value });
    }

    const showButton = () => {
        $('#content-button').animate({
            height: slideUp ? 0 : 260,
            opacity: slideUp ? 0 : 1
        }, 500);
        setSlideup(!slideUp);
    }

    const openLink = link => () => {
        window.open(link);
    }

    const showImageDetail = (i) => {
        setState({ imageIndex: i });
        $(`#image-detail-container`).animate({
            height: '100vh',
            opacity: 1
        }, 200);
    }

    const closeImageDetail = () => {
        setState({ imageIndex: -1 });
        $(`#image-detail-container`).animate({
            height: 0,
            opacity: 0
        }, 200);
    }

    const onShow3D = () => {
        setState({ show3D: true });
    }

    const onHide3D = () => {
        setState({ show3D: false });
    }

    const getProduct = (lang, id) => {
        const newLang = lang === 'ID' ? 'ID' : (lang === 'EN' ? 'EN' : 'EN');
        const newGlobal = lang === 'ID' ? false : (lang === 'EN' ? false : true);
        getProductByLangId(newLang, id).then(res => {
            const { dimension, media, product_details, url_ar } = res.data;
            const detail = product_details;
            const { image, video } = media;

            const productImageList = image ? image.filter(e => e.media_type.includes('product_image')) : [];
            const bannerList = image ? image.filter(e => e.media_type.includes('banner')) : [];
            const iconList = image ? image.filter(e => e.media_type.includes('icon')) : [];
            const videoPreview = video ? video.filter(e => e.media_type.includes('preview')) : [];

            const iconDumps = [];
            for (let i = 0; i < (3 - iconList.length); i++) {
                iconDumps.push({
                    image_url: no_image1,
                    media_desc: ''
                });
            }

            setState({
                isLoaded: true,
                isDataExist: true,
                tagline: detail.tagline,
                title: detail.title,
                feature: detail.feature ? detail.feature.split(/(?:\r\n|\r|\n)/g) : detail.feature,
                manualBook: detail.manual_book != '' ? detail.manual_book : false,
                productImage: productImageList.length > 0 ? productImageList : [],
                urlAr: url_ar,
                show3D: url_ar ? true : false,
                banner: bannerList.length > 0 ? bannerList[0].image_url : no_image1,
                icons: iconList,
                iconDumps,
                dimensions: dimension,
                videoPreview: videoPreview.length > 0 ? process.env.REACT_APP_WATCH_YOUTUBE + videoPreview[0].video_url.replace(process.env.REACT_APP_EMBED_YOUTUBE, '') : false,
                processing: false,
                global: newGlobal
            });

        }).catch(error => {
            const iconDumps = [];
            for (let i = 0; i < 3; i++) {
                iconDumps.push({
                    image_url: no_image1,
                    media_desc: ''
                });
            }

            setState({
                iconDumps,
                isLoaded: true,
                isDataExist: false,
                processing: false,
                global: false
            });
            console.log('error', error);
        });
    }

    useEffect(() => {
        startProcessing();
        setTimeout(() => {
            getProduct(lang, id);
            let showUp = true;
            let dividedBy = 60 * 60;
            let _showtime = localStorage.getItem('showtime');
            if (_showtime) {
                _showtime = JSON.parse(atob(_showtime));
                let showTime = parseInt(_showtime.showtime / 1000);
                let currTime = parseInt(new Date().getTime() / 1000);
                if (parseInt(showTime / dividedBy) == parseInt(currTime / dividedBy)) {
                    showUp = false;
                }
            }

            if (showUp) {
                let showtime = new Date().getTime(); //milisecond
                localStorage.setItem('showtime', btoa(JSON.stringify({ showtime })));
                $('#pop-warranty').modal();
            }
        }, 1000);
    }, []);

    useEffect(() => {
        console.log(isDataExist);
    }, [state]);

    const {
        tagline,
        title,
        feature,
        manualBook,
        productImage,
        urlAr,
        banner,
        icons,
        iconDumps,
        dimensions,
        videoPreview,
        processing,
        isDataExist,
        isLoaded,
        imageIndex,
        show3D,
        global
    } = state;

    let renderContent = <div style={{ cursor: 'pointer', border: 'solid 0px', background: 'white', padding: 5 }}>
        <img src={no_image1} style={{ width: '100%' }} />
    </div>;

    if (productImage.length > 0) {
        renderContent = <AwesomeSlider
            mobileTouch={true}
            style={{
                height: 600,
                width: "100%"
            }}
            bullets={false}
            transitionDelay={0}
            infinite={false}
        >

            {
                productImage && productImage.map((item, i) => {
                    return (
                        <div key={i} style={{ cursor: 'pointer', border: 'solid 0px', background: 'white' }} onClick={() => showImageDetail(i)}>
                            <img src={item.image_url} className="w-100" />
                        </div>
                    );
                })
            }
        </AwesomeSlider>
    }

    return (
        <>
            <Overlay display={processing} />
            {
                isDataExist && isLoaded
                    ? (
                        <div className='wrapper'>

                            {
                                <div id={`image-detail-container`} style={{ height: 0, opacity: 0 }}>
                                    <div id="image-detail-content">
                                        <div className='d-flex justify-content-end'>
                                            <button type='button' className='btn btn-circle btn-outline-dark' onClick={() => closeImageDetail()} style={{ margin: 12 }}><i className="fa fa-times"></i></button>
                                        </div>
                                        <img src={productImage[imageIndex]?.image_url} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            }

                            <div id="main-content" style={{ background: '#f2f2f2' }}>
                                <div style={{ background: 'white', padding: 16 }}>
                                    <div className="d-flex justify-content-center">
                                        <img src={logo_modena} alt="" style={{ width: 77, height: 'auto', }} />
                                    </div>
                                    <div className="d-flex justify-content-center mt-5">
                                        <h1 style={{ fontFamily: 'Inter', width: 200, font: 'inter', fontSize: 30, fontWeight: 700, textAlign: 'center' }}>{tagline}</h1>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <h5 style={{ fontFamily: 'Inter', width: 206, font: 'inter', fontSize: 14, color: '#F29C37', fontWeight: 700, textAlign: 'center' }}>{title}</h5>
                                    </div>
                                </div>

                                {
                                    urlAr && show3D &&
                                    <div className='text-center' style={{ width: "100%", backgroundColor: "transparent" }}>
                                        <div>{parse(urlAr)}</div>
                                    </div>
                                }

                                {
                                    !show3D && renderContent
                                }

                                {
                                    urlAr && productImage.length > 0 && (
                                        <div className={`d-flex justify-content-between align-items-center`}>
                                            <div className='w-50 text-center p-2 pointer' style={show3D ? { background: "#ddd", marginTop: '-6px' } : {}} onClick={onShow3D}>
                                                <img className='grayscale' src={url_ar} width={50} height={50} /><br />
                                                <span>Augmented Reality</span>
                                            </div>
                                            <div className='w-50 text-center p-2 pointer' style={!show3D ? { background: "#ddd" } : { marginTop: '-6px' }} onClick={onHide3D} >
                                                <img className='grayscale' src={camera} width={50} height={50} /><br />
                                                <span>Captured Images</span>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    urlAr && productImage.length == 0 && (
                                        <div className={`d-flex justify-content-between align-items-center`}>
                                            <div className='w-100 text-center p-2 pointer' style={show3D ? { background: "#ddd", marginTop: '-6px' } : {}} onClick={onShow3D}>
                                                <img className='grayscale' src={url_ar} width={50} height={50} /><br />
                                                <span>Augmented Reality</span>
                                            </div>
                                        </div>
                                    )
                                }

                                <div style={{}}>
                                    <div className="d-flex justify-content-between" style={{ background: 'white' }}>
                                        {
                                            icons && icons.map((item, i) => {
                                                return (
                                                    <div className="col-4" key={i}>
                                                        <div style={{ textAlign: 'center', margin: 16 }}>
                                                            <img src={item.image_url} style={{ width: 30 }} />
                                                        </div>
                                                        <div style={{ padding: '0 16px' }}>
                                                            <p style={{ textAlign: 'center', fontSize: 12 }}>{item.media_desc}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            iconDumps && iconDumps.map((item, i) => {
                                                return (
                                                    <div className="col-4" key={i}>
                                                        <div style={{ textAlign: 'center', margin: 16 }}>
                                                            <img src={item.image_url} style={{ width: 30 }} />
                                                        </div>
                                                        <div style={{ padding: '0 16px' }}>
                                                            <p style={{ textAlign: 'center', fontSize: 12 }}>{item.media_desc}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>

                                    {global &&
                                        <div>
                                            <img src={banner} style={{ width: '100%' }} />
                                        </div>
                                    }

                                    <div className="d-flex" style={{ padding: 16 }}>
                                        <div style={{ flex: 3 }} >
                                            <div style={{ fontSize: 14, lineHeight: 2, fontWeight: 'bold' }}>Product Features</div>
                                            <div style={{ color: '#929292' }}>
                                                {feature && feature.length > 1
                                                    ? feature.map((item, i) => <div key={i} style={{ fontSize: 12, lineHeight: 1.8 }}>{item}</div>)
                                                    : <div style={{ fontSize: 12, lineHeight: 1.8 }}>{feature}</div>}
                                            </div>
                                        </div>

                                        <div style={{ flex: 2 }} >
                                            <div style={{ fontSize: 14, lineHeight: 2, fontWeight: 'bold' }}>Product Dimension</div>
                                            <div style={{ color: '#929292' }}>
                                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Width: {dimensions.product?.width} </div>

                                                {
                                                    dimensions.product?.length
                                                        ? <div style={{ fontSize: 12, lineHeight: 1.8 }}>Length: {dimensions.product?.length} </div>
                                                        : <div style={{ fontSize: 12, lineHeight: 1.8 }}>Depth: {dimensions.product?.depth} </div>
                                                }

                                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Height: {dimensions.product?.height} </div>
                                                <div style={{ fontSize: 12, lineHeight: 1.8 }}>Weight: {dimensions.product?.weight} </div>
                                            </div>
                                        </div>
                                    </div>

                                    {!global ?
                                        <div className="mt-5" style={{ margin: 20 }}>
                                            <div className='d-flex justify-content-center mt-2'><button disabled={!videoPreview} type="button" onClick={videoPreview ? openLink(videoPreview) : () => console.log('video is not provide')} className="btn btn-block btn-outline-dark">Product Video</button></div>
                                            <div className='d-flex justify-content-center mt-2'><button type="button" onClick={openLink('https://www.modena.com/id_id/account/product-registration')} className="btn btn-block btn-outline-dark">Product Registration</button></div>
                                            <div className='d-flex justify-content-center mt-2'><button disabled={!manualBook} type="button" onClick={manualBook ? openLink(manualBook) : () => console.log('manual book is not provide')} className="btn btn-block btn-outline-dark">Download Manual Book</button></div>
                                            <div 
                                                className='d-flex justify-content-center mt-2'
                                            >
                                                <button 
                                                    type="button" 
                                                    onClick={() => { 
                                                        const message = encodeURIComponent(
                                                            `Halo MODENA, saya tertarik ingin cari tahu lebih lanjut mengenai spare part untuk produk ${title}`
                                                        );
                                                        window.open(`https://wa.me/6281511500715?text=${message}`, "_blank") 
                                                    }} 
                                                    className="btn btn-block btn-outline-dark">Spare Parts and Accessories
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="mt-5" style={{ margin: 20, textAlign: 'center' }}>
                                            <div className='d-inline'><button type="button" disabled={!manualBook} onClick={manualBook ? openLink(manualBook) : () => console.log('manual book is not provide')} className="btn btn-app btn-outline-dark"><i className="fa fa-download"></i><p style={{ padding: '15px 0', fontWeight: '600' }}>Download<br />Manual Book</p></button></div>
                                            <div className='d-inline'><button type="button" onClick={openLink('mailto:care.uae@modena.com?subject=Email Us - O2O')} className="btn btn-app btn-outline-dark"><i className="fa fa-envelope"></i><p style={{ padding: '35px 0', fontWeight: '600' }}>Email Us</p></button></div>
                                        </div>
                                    }

                                    <div style={{ height: 120 }}>
                                        <div style={{ textAlign: 'center', fontSize: 10, color: '#929292' }}>
                                            © MODENA 2021 | All rights reserved
                                        </div>
                                    </div>
                                </div>
                                <div id="pop-warranty" className="modal fade">
                                    <div className="modal-dialog" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150 }}>
                                        <img src={mona_apron} style={{ width: 160 }} />
                                        <div className="modal-content" style={{ borderRadius: 20, width: 260, }}>
                                            <div className="modal-body">
                                                <div className='d-flex justify-content-end'>
                                                    <span type='button' className='close' style={{ fontSize: 16, fontWeight: 'normal' }} data-dismiss="modal"> &times; </span>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <h1 style={{ fontSize: 17, fontWeight: '700', marginTop: 10 }}>Free Extended Warranty</h1>
                                                    <h5 style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: 'normal' }}>with <span style={{ color: 'orange' }}>Mona</span></h5>
                                                    <p style={{ fontSize: 10, color: '#929292' }}>Extend your product warranty with MONA,<br />our all-in-one virtual assistant.</p>
                                                    <button onClick={openLink('https://mona.modena.com/#/video-call')} className="btn btn-dark" style={{ width: 140, height: 28, borderRadius: 10, fontSize: 10 }}>Contact Mona</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {!global &&
                                <div id="button-container" style={{ zIndex: 9999 }}>
                                    <div id="floating-button">
                                        <div id="content-button">
                                            <div>
                                                <img src={call_icon} onClick={openLink('tel:1500715')} />
                                            </div>
                                            <div>
                                                <img src={chat_icon} onClick={openLink('https://mona.modena.com/#/chat')} />
                                            </div>
                                            <div>
                                                <img src={vcall_icon} onClick={openLink('https://mona.modena.com/#/video-call')} />
                                            </div>
                                        </div>
                                        <div className='btn btn-outline-dark' onClick={showButton} style={{ zIndex: 1000, display: 'flex', borderRadius: '50%', width: 68, height: 68, margin: 20, alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={button_icon} style={{ width: 70, height: 70, marginTop: 8 }} />
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    )
                    : (
                        <>
                            {
                                !isDataExist && isLoaded &&
                                <div id="main-content" style={{ background: '#f2f2f2', height: "100vh" }}>
                                    {/** top title and product name */}
                                    <div style={{ background: 'white', padding: 16, height: "100vh" }}>

                                        <div className="d-flex justify-content-center">
                                            <img src={logo_modena} alt="" style={{ width: 77, height: 'auto', }} />
                                        </div>

                                        <div className='d-flex flex-column text-center justify-content-center align-items-center h-100 w-100'>
                                            <img src={mona_apron} style={{ width: 160 }} />

                                            <div className="d-flex justify-content-center mt-5">
                                                <h5 style={{ fontFamily: 'Inter', width: '100%', font: 'inter', fontSize: 34, color: '#F29C37', fontWeight: 700, textAlign: 'center' }}>404</h5>
                                            </div>

                                            <div className="d-flex justify-content-center mt-1">
                                                <h5 style={{ fontFamily: 'Inter', width: '100%', font: 'inter', fontSize: 34, color: '#F29C37', fontWeight: 700, textAlign: 'center' }}>Product Not Found!</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
            }

        </>
    )


}

export default ProductDetailPage;